<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ salesOrder.code }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              salesOrder.status === ORDER_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_SALES_ORDERS)
            "
            @click="deleteSaleOrder"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              salesOrder.status === ORDER_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_SALES_ORDERS)
            "
            @click="editSaleOrder"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ salesOrder.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SALES_ORDERS.EXPIRATION_TIME") }}</dt>
          <dd>
            {{ salesOrder.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesOrder.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesOrder.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <sales-order-status-badge
              :salesOrder="salesOrder"
              :advanced="true"
            />
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.salesInvoice">
          <dt>{{ $t("COMMON.SALES_INVOICE") }}</dt>
          <dd>
            <object-link :object="salesOrder.salesInvoice" />
            <sales-invoice-status-badge
              :salesInvoice="salesOrder.salesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="salesOrder.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd v-if="salesOrder.pricing">
            <span> {{ $formatCurrency(salesOrder.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt v-if="salesOrder.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd v-if="salesOrder.excerpt">
            <div v-html="salesOrder.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 mt-3 mb-3">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl
          class="row"
          v-if="
            salesOrder.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ salesOrder.billing_lastname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesOrder.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ salesOrder.billing_firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="salesOrder.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY"
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ salesOrder.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ salesOrder.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ salesOrder.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ salesOrder.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ salesOrder.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ salesOrder.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ salesOrder.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesInvoiceStatusBadge from "../../SalesInvoiceManagement/partials/SalesInvoiceStatusBadge.vue";
import SalesOrderStatusBadge from "./SalesOrderStatusBadge.vue";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  name: "sales-order-view-global",

  components: {
    SalesOrderStatusBadge,
    SalesInvoiceStatusBadge,
  },

  props: ["salesOrder"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
      ORDER_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesOrder(salesOrder) {},
  },
};
</script>
