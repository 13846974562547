<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="table-responsive align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="salesOrder.items"
        >
          <el-table-column :label="$t('COMMON.CODE')" prop="code">
            <template v-slot="{ row }">
              <div v-if="row.salesOrderable">
                <a
                  href="#"
                  @click.prevent="goToSalesOrderable(row.salesOrderable)"
                >
                  {{ row.code }}<br />
                  <div
                    class="text-muted sales-order-item-excerpt"
                    v-html="row.excerpt"
                  ></div>
                </a>
              </div>
              <div v-if="!row.salesOrderable">
                {{ row.code }}<br />
                <div
                  class="text-muted sales-order-item-excerpt"
                  v-html="row.excerpt"
                ></div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.UNIT_PRICE')" prop="unit_price">
            <template v-slot="{ row }"> {{ $formatCurrency(row.unit_price) }} </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.QUANTITY')" prop="quantity" />

          <el-table-column :label="$t('COMMON.SUBTOTAL')" prop="discount">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.pricing.subtotal.toFixed(2)) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.DISCOUNT')" prop="discount">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.pricing.discount_amount.toFixed(2)) }}
              <span class="text-muted">({{ row.discount }}%)</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.TAXES')">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.pricing.taxes.total.toFixed(2)) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.TOTAL')">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.pricing.total.toFixed(2)) }}
            </template>
          </el-table-column>

          <el-table-column>
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="salesOrder.status === ORDER_STATUS_DRAFT"
              >
                <a
                  type="text"
                  @click="openSalesOrderItemModal(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  name: "sales-order-view-details-sales-order-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["salesOrder"],

  data() {
    return {
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openSalesOrderItemModal(salesOrderItem) {
      this.$emit("onOpenSalesOrderItemModal", salesOrderItem);
    },

    async goToSalesOrderable(salesOrderable) {
      this.$router.push(this.$objectViewRoute(salesOrderable));
    },
  },

  mounted() {},

  watch: {},
};
</script>

<style>
.sales-order-item-excerpt p,
.sales-order-item-excerpt,
.sales-order-item-excerpt * {
  font-size: 0.7rem;
}
</style>
