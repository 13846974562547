<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesOrder">
      <span class="loader"></span>
    </span>
    <div v-if="salesOrder" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="details"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <sales-order-view-global
            :salesOrder="salesOrder"
            @onEditSalesOrder="editSalesOrder"
            @onDeleteSalesOrder="deleteSalesOrder"
          />
        </tab-pane>

        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67"></i>
            {{ $t("COMMON.DETAILS") }}
          </span>
          <sales-order-view-details
            :salesOrder="salesOrder"
            @salesOrderItemsUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="3" :active="false">
          <span slot="title">
            <i class="ni ni-single-copy-04" />
            {{ $t("SALES_ORDERS.SALES_INVOICES") }}
          </span>
          <sales-order-view-invoices
            :salesOrder="salesOrder"
            @salesOrderInvoicesUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="4" :active="false">
          <span slot="title">
            <i class="fa fa-truck"></i>
            {{ $t("SALES_ORDERS.DELIVERIES_STATE") }}
          </span>
          <sales-order-view-delivery-state
            :salesOrder="salesOrder"
            @salesOrderDeliveiesUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="5" :active="false">
          <span slot="title">
            <i class="fa fa-truck"></i>
            {{ $t("COMMON.SALES_DELIVERIES") }}
          </span>
          <sales-order-view-deliveries
            :salesOrder="salesOrder"
            @salesOrderDeliveiesUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            salesOrder.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="salesOrder" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <sales-order-view-logs :salesOrder="salesOrder" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_VALIDATED,
} from "@/constants/orders";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import SalesOrderViewGlobal from "../partials/SalesOrderViewGlobal.vue";
import SalesOrderViewDetails from "../partials/SalesOrderViewDetails.vue";
import SalesOrderViewLogs from "../partials/SalesOrderViewLogs.vue";
import SalesOrderViewDeliveries from "../partials/SalesOrderViewDeliveries.vue";
import SalesOrderViewDeliveryState from "../partials/SalesOrderViewDeliveryState.vue";
import SalesOrderViewInvoices from "../partials/SalesOrderViewInvoices.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SalesOrderViewGlobal,
    SalesOrderViewDetails,
    SalesOrderViewLogs,
    ListFileComponent,
    SalesOrderViewDeliveries,
    SalesOrderViewDeliveryState,
    SalesOrderViewInvoices,
  },

  mixins: [requestErrorMixin],

  props: {
    salesOrderId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      salesOrder: null,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("salesOrders/get", this.salesOrderId);
        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.$emit("salesOrderLoaded", this.salesOrder);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editSalesOrder(salesOrder) {
      this.$emit("onEditSalesOrder", salesOrder);
    },
    deleteSalesOrder(salesOrder) {
      this.$emit("onDeleteSalesOrder", salesOrder);
    },
  },
};
</script>
