<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("SALES_ORDERS.SALES_ORDERS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openSalesOrderCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_SALES_ORDERS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("SALES_ORDERS.ADD_SALES_ORDER") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_SALES_ORDERS)"
              :objectType="'sales-orders'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <sales-order-list-table
        @onViewSalesOrder="openSalesOrderViewModal"
        @onEditSalesOrder="openSalesOrderEditModal"
        @onDeleteSalesOrder="deleteSalesOrder"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSalesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSalesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_ORDER_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_ORDERS.VIEW_SALES_ORDER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="validateSalesOrder(openSalesOrder)"
                v-if="
                  openSalesOrderLoaded
                    ? openSalesOrder.items.length > 0 &&
                      openSalesOrder.status === ORDER_STATUS_DRAFT
                    : false
                "
                :title="$t('SALES_ORDERS.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-check-bold"></i>
                </span>
              </base-button>
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_ROLES)"
              >
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      salesOrderInvoiceFormModalOpened = true;
                    }
                  "
                  v-if="
                    openSalesOrderLoaded
                      ? openSalesOrder.status == ORDER_STATUS_VALIDATED &&
                        openSalesOrder.invoicing_status !=
                          INVOICING_STATUS_INVOICED
                      : false
                  "
                  :title="$t('SALES_ORDERS.ADD_INVOICE')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-single-copy-04"></i>
                  </span>
                </base-button>
              </el-tooltip>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="
                  () => {
                    salesOrderDeliveryFormModalOpened = true;
                  }
                "
                v-if="
                  openSalesOrderLoaded
                    ? openSalesOrder.status == ORDER_STATUS_VALIDATED &&
                      openSalesOrder.delivery_status !=
                        DELIVERY_STATUS_DELIVERED
                    : false
                "
                :title="$t('SALES_ORDERS.ADD_DELIVERY')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-truck"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelSalesOrder(openSalesOrder)"
                v-if="canCancelSalesOrder"
                :title="$t('SALES_ORDERS.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openSalesOrder"
                :objectType="'SalesOrders'"
                :objectId="openSalesOrder.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <!-- <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  v-if="
                    openSalesOrder.status === ORDER_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_SALES_ORDERS)
                  "
                  @click="openSalesOrderEditModal(openSalesOrder)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  v-if="
                    openSalesOrder.status === ORDER_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_SALES_ORDERS)
                  "
                  @click="deleteSalesOrder(openSalesOrder)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown> -->

              <button class="close" @click="closeSalesOrderModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-sales-order-page
              v-if="openSalesOrder"
              :salesOrderId="openSalesOrder.id"
              @salesOrderLoaded="
                (salesOrder) => {
                  openSalesOrder = salesOrder;
                  openSalesOrderLoaded = true;
                }
              "
              @onEditSalesOrder="openSalesOrderEditModal"
              @onDeleteSalesOrder="deleteSalesOrder"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSalesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSalesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_ORDER'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_ORDERS.EDIT_SALES_ORDER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openSalesOrderViewModal(openSalesOrder)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeSalesOrderModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-sales-order-page
              v-if="openSalesOrder"
              :salesOrderId="openSalesOrder.id"
              @onViewSalesOrder="openSalesOrderViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSalesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSalesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_ORDER'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("SALES_ORDERS.ADD_SALES_ORDER") }}
            </h1>
            <button class="close" @click="closeSalesOrderModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-sales-order-page @onViewSalesOrder="openSalesOrderViewModal" />
          </div>
        </div>
      </div>
    </div>

    <sales-order-view-add-delivery-form
      v-if="
        openSalesOrderLoaded
          ? !openSalesOrder.deliveryItemsState.orderDelivered
          : false
      "
      :salesOrder="openSalesOrder"
      :showModal="salesOrderDeliveryFormModalOpened"
      @closeSalesOrderDeliveryForm="
        () => {
          salesOrderDeliveryFormModalOpened = false;
        }
      "
    />

    <sales-order-view-add-invoice-form
      v-if="
        openSalesOrderLoaded
          ? !openSalesOrder.invoicingItemsState.orderInvoiced
          : false
      "
      :salesOrder="openSalesOrder"
      :showModal="salesOrderInvoiceFormModalOpened"
      @closeSalesOrderInvoiceForm="
        () => {
          salesOrderInvoiceFormModalOpened = false;
        }
      "
    />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_VALIDATED,
  INVOICING_STATUS_INVOICED,
  DELIVERY_STATUS_DELIVERED,
} from "@/constants/orders";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import requestErrorMixin from "@/mixins/request-error-mixin";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesOrderListTable from "./partials/SalesOrderListTable.vue";
import EditSalesOrderPage from "./components/EditSalesOrderComponent.vue";
import AddSalesOrderPage from "./components/AddSalesOrderComponent.vue";
import ViewSalesOrderPage from "./components/ViewSalesOrderComponent.vue";
import SalesOrderViewAddDeliveryForm from "./partials/SalesOrderViewAddDeliveryForm.vue";
import SalesOrderViewAddInvoiceForm from "./partials/SalesOrderViewAddInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SalesOrderListTable,
    NotificationSubscription,
    EditSalesOrderPage,
    AddSalesOrderPage,
    ViewSalesOrderPage,
    [Button.name]: Button,
    SalesOrderViewAddDeliveryForm,
    SalesOrderViewAddInvoiceForm,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelSalesOrder() {
      if (!this.openSalesOrder) {
        return false;
      }
      if (
        this.openSalesOrder.status === ORDER_STATUS_CANCELED ||
        this.openSalesOrder.status === ORDER_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openSalesOrder.status === ORDER_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSalesOrderModalOpened = false;
    let isEditSalesOrderModalOpened = false;
    let isAddSalesOrderModalOpened = false;
    let openSalesOrder = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSalesOrderModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSalesOrderModalOpened = true;
      }
      openSalesOrder = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSalesOrderModalOpened = true;
    }
    return {
      isViewSalesOrderModalOpened: isViewSalesOrderModalOpened,
      isEditSalesOrderModalOpened: isEditSalesOrderModalOpened,
      isAddSalesOrderModalOpened: isAddSalesOrderModalOpened,
      openSalesOrder: openSalesOrder,
      openSalesOrderLoaded: false,
      renderKey: 1,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      INVOICING_STATUS_INVOICED: INVOICING_STATUS_INVOICED,
      DELIVERY_STATUS_DELIVERED: DELIVERY_STATUS_DELIVERED,
      salesOrderDeliveryFormModalOpened: false,
      salesOrderInvoiceFormModalOpened: false,
    };
  },

  methods: {
    openSalesOrderCreateModal() {
      this.closeSalesOrderModal();
      this.isAddSalesOrderModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesOrders",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openSalesOrderViewModal(salesOrder, reRender = false) {
      this.closeSalesOrderModal();
      this.openSalesOrder = salesOrder;
      this.isViewSalesOrderModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesOrders",
          query: { id: this.openSalesOrder.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSalesOrderEditModal(salesOrder) {
      this.closeSalesOrderModal();
      this.openSalesOrder = salesOrder;
      this.isEditSalesOrderModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesOrders",
          query: { id: this.openSalesOrder.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeSalesOrderModal() {
      this.isAddSalesOrderModalOpened = false;
      this.isViewSalesOrderModalOpened = false;
      this.isEditSalesOrderModalOpened = false;
      this.openSalesOrderLoaded = false;
      this.openSalesOrder = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesOrders",
          query: {},
        }).href
      );
    },

    async validateSalesOrder(salesOrder) {
      try {
        await this.$store.dispatch("salesOrders/validate", salesOrder.id);
        this.openSalesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_VALIDATED"),
        });
        this.renderKey++;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelSalesOrder(salesOrder) {
      const confirmation = await swal.fire({
        text: this.$t("SALES_ORDERS.CANCEL_SALES_ORDER_QUESTION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("salesOrders/cancel", salesOrder.id);
        this.openSalesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_CANCELED"),
        });
        this.renderKey++;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSalesOrder(salesOrder) {
      const confirmation = await swal.fire({
        text: this.$t("SALES_ORDERS.DELETE_THIS_SALES_ORDER"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesOrders/destroy", salesOrder.id);
          this.renderKey++;
          this.closeSalesOrderModal();
          this.$notify({
            type: "success",
            message: this.$t("SALES_ORDERS.SALES_ORDER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
